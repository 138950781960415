<template>
    <div>

     <!-- 
        Primary Member (Employee)
    -->            
    <div class="mx-auto pb-10">
        <v-row>
            <!-- First Name  -->

            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.firstName != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.firstName" 
                                    :items="selectedColumns"
                                    :item-text="firstName"
                                    :item-value="firstName"
                                    label="First Name"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.firstName); member.firstName = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.firstName == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeFirstName-text"
                                ref="employeeFirstName"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.firstName" 
                                :rules="firstNameMessage" 
                                :items="mappingList"
                                item-text="firstName"
                                item-value="firstName" 
                                @input="handleSelect(`${member.firstName}`, 'Employee First Name')"
                                label="First Name"
                                required
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
                        

            <!-- Last Name  -->

            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.lastName != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.lastName" 
                                    :items="selectedColumns"
                                    :item-text="lastName"
                                    :item-value="lastName"
                                    label="Last Name"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.lastName); member.lastName = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.lastName == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeLastName-text"
                                ref="employeeLastName"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.lastName" 
                                :rules="lastNameMessage" 
                                :items="mappingList"
                                item-text="lastName"
                                item-value="lastName" 
                                @input="handleSelect(`${member.lastName}`, 'Employee Last Name')"
                                label="Last Name"
                                required
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- DOB  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.dateOfBirth != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.dateOfBirth" 
                                    :items="selectedColumns"
                                    :item-text="dateOfBirth"
                                    :item-value="dateOfBirth"
                                    label="Date of Birth"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.dateOfBirth); member.dateOfBirth = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.dateOfBirth == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeedateOfBirth-text"
                                ref="employeedateOfBirth"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.dateOfBirth" 
                                :rules="dobMessage" 
                                :items="mappingList"
                                item-text="dateOfBirth"
                                item-value="dateOfBirth" 
                                @input="handleSelect(`${member.dateOfBirth}`, 'Employee Date of Birth')"
                                label="Date of Birth"
                                required
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Gender  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.gender != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.gender" 
                                    :items="selectedColumns"
                                    :item-text="gender"
                                    :item-value="gender"
                                    label="Gender"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.gender); member.gender = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.gender == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeGender-text"
                                ref="employeeGender"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.gender" 
                                :items="mappingList"
                                item-text="gender"
                                item-value="gender" 
                                @input="handleSelect(`${member.gender}`, 'Employee Gender')"
                                label="Gender"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            
            <!-- Email  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.email != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.email" 
                                    :items="selectedColumns"
                                    :item-text="email"
                                    :item-value="email"
                                    label="Email"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.email); member.email = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.email == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeEmail-text"
                                ref="employeeEmail"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.email" 
                                :rules="emailMessage" 
                                :items="mappingList"
                                item-text="email"
                                item-value="email" 
                                @input="handleSelect(`${member.email}`, 'Employee Email')"
                                label="Email"
                                required
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Phone Number  -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.phoneNumber != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.phoneNumber" 
                                    :items="selectedColumns"
                                    :item-text="phoneNumber"
                                    :item-value="phoneNumber"
                                    label="Phone Number"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.phoneNumber); member.phoneNumber = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.phoneNumber == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeePhoneNumber-text"
                                ref="employeePhoneNumber"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.phoneNumber" 
                                :items="mappingList"
                                item-text="phoneNumber"
                                item-value="phoneNumber" 
                                @input="handleSelect(`${member.phoneNumber}`, 'Employee Phone Number')"
                                label="Phone Number"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            
            <!-- Address 1 -->            
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.address1 != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.address1" 
                                    :items="selectedColumns"
                                    :item-text="address1"
                                    :item-value="address1"
                                    label="Address 1"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.address1); member.address1 = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.address1 == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeAddress1-text"
                                ref="employeeAddress1"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.address1" 
                                :items="mappingList"
                                item-text="address1"
                                item-value="address1" 
                                @input="handleSelect(`${member.address1}`, 'Employee Address 1')"
                                label="Address 1"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>       
            
            <!-- Address 2 -->            
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.address2 != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.address2" 
                                    :items="selectedColumns"
                                    :item-text="address2"
                                    :item-value="address2"
                                    label="Address 1"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.address2); member.address2 = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.address2 == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeAddress2-text"
                                ref="employeeAddress2"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.address2" 
                                :items="mappingList"
                                item-text="address2"
                                item-value="address2" 
                                @input="handleSelect(`${member.address2}`, 'Address 2')"
                                label="Address 2"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- City -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.city != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.city" 
                                    :items="selectedColumns"
                                    :item-text="city"
                                    :item-value="city"
                                    label="City"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.city); member.city = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.city == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeCity-text"
                                ref="employeeCity"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.city" 
                                :items="mappingList"
                                item-text="city"
                                item-value="city" 
                                @input="handleSelect(`${member.city}`, 'Employee City')"
                                label="City"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- State -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.state != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.state" 
                                    :items="selectedColumns"
                                    :item-text="state"
                                    :item-value="state"
                                    label="State"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.state); member.state = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.state == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeState-text"
                                ref="employeeState"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.state" 
                                :items="mappingList"
                                item-text="state"
                                item-value="state" 
                                @input="handleSelect(`${member.state}`, 'Employee State')"
                                label="State"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Zip code -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.zipCode != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.zipCode" 
                                    :items="selectedColumns"
                                    :item-text="zipCode"
                                    :item-value="zipCode"
                                    label="Zip Code"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.zipCode); member.zipCode = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.zipCode == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeZipCode-text"
                                ref="employeeZipCode"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.zipCode" 
                                :items="mappingList"
                                item-text="zipCode"
                                item-value="zipCode" 
                                @input="handleSelect(`${member.zipCode}`, 'Employee Zip Code')"
                                label="Zip Code"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>       
            
            <!-- Language -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.language != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.language" 
                                    :items="selectedColumns"
                                    :item-text="language"
                                    :item-value="language"
                                    label="Language"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.language); member.language = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.language == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeLanguage-text"
                                ref="employeeLanguage"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.language" 
                                :items="mappingList"
                                item-text="language"
                                item-value="language" 
                                @input="handleSelect(`${member.language}`, 'Employee Language')"
                                label="Language"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            
            <!-- External ID -->
            <v-col sm="6" >
                <v-row>
                    <v-col v-if="member.externalID != ''">
                        <v-row>
                            <v-col sm="11">
                                <v-select
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="member.externalID" 
                                    :items="selectedColumns"
                                    :item-text="externalID"
                                    :item-value="externalID"
                                    label="External ID"
                                    :disabled="true"
                                    >
                                </v-select>
                            </v-col>
                            <v-col sm="1" class="mt-3">
                                <v-icon v-on:click="handleClearSelection(member.externalID); member.externalID = ''">
                                    mdi-close
                                </v-icon>
                            </v-col>
                            </v-row>
                    </v-col>     
                    <v-col sm="12" v-if="member.externalID == ''">
                        <span class="d-flex">
                            <v-select
                                id="employeeExternalID-text"
                                ref="employeeExternalID"
                                class="pt-1 pl-sm-6"
                                v-model.trim="member.externalID" 
                                :items="mappingList"
                                item-text="externalID"
                                item-value="externalID" 
                                @input="handleSelect(`${member.externalID}`, 'External ID')"
                                label="External ID"
                                >
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
        
    </div>

</template>
<script>
import { useEmployerStore } from "../../../stores/employer";

export default {
    name: "EmployeetMapping",
    props: ["member", "mappingList", "selectedColumns", "activeColumns", "useFileHeaders"],
    setup() {
        const employerStore = useEmployerStore();
        return { employerStore };
    },
    data(){
        return {
            mappingFile: null,
            firstNameMessage:  [
                v => !!v || 'First Name is Required'
            ],
            lastNameMessage:  [
                v => !!v || 'Last Name is Required'
            ],
            dobMessage:  [
                v => !!v || 'Date of Birth is Required'
            ],
            emailMessage:  [
                v => !!v || 'Email is Required'
            ]
        }
    },
    mounted() {

    },
    computed: {
        
    },
    methods: {
        handleSelect(event, mapping) {
            this.mappingList.splice(this.mappingList.indexOf(event), 1);            
            let selectedColumn = this.activeColumns.filter((item) => {
                                    return item.columnName == event;
                                });

            if(selectedColumn.length == 0){
                this.activeColumns.push({
                    columnName: event,
                    mapping: mapping
                });
            }
        },

        handleClearSelection: function(value){
            let mapItem = this.mappingList.find(x => x == value);
            if(!mapItem){
                this.mappingList.push(value);
            }

            this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == value), 1);
        }        
    }
}
</script>
